import { memo, useEffect, useState } from 'react';
import { collectibleNames } from '../../collectibles/constants';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import WidgetHeader from '../../../sci-ui-components/widgets/WidgetHeader/WidgetHeader';
import SimpleSlider from '../../../sci-ui-components/SimpleSlider/SimpleSlider';
import WidgetFiltersRow from '../../../sci-ui-components/widgets/WidgetFiltersRow/WidgetFiltersRow';
import TextSearchField from '../../../sci-ui-components/forms/TextSearchField/TextSearchField';
import useDebouncedValue from '../../../sci-ui-components/hooks/useDebouncedValue';
import useListFavoriteCollectiblesInfinite from '../useListCollectibleFavoritesInfinite';
import useFavoriteCollectibleCategoriesByType from '../useFavoriteCollectibleCategoriesByType';
import useFavoriteCollectibleMutations from '../useFavoriteCollectibleMutations';
import FavoriteCategoryFilter from '../FavoriteCategoryFilter';
import { editFavorite } from '../AddOrEditFavoriteDialog';
import { SportsSingleSelectFilter } from '../../sports/SportsSingleSelectFilter';
import FavoriteTile from './FavoriteTile';
import classes from './FavoritesWidget.module.scss';
import { trackEvent } from '@/features/analytics/trackEvent';

const emptyItems = Array.from({ length: 10 }).map(() => null);
const numberOfItemsLeftToStartFetchingNextPage = 15;

export default memo(function FavoritesWidget({
  className,
  collectibleType,
}: {
  className?: string;
  collectibleType: CollectibleType;
}) {
  const { data: favoriteCategories } = useFavoriteCollectibleCategoriesByType({
    collectibleType,
  });
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [sportId, setSportId] = useState<number | null>(null);
  const [debouncedSearchText] = useDebouncedValue(searchText);
  const { allItems, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } = useListFavoriteCollectiblesInfinite({
    filters: {
      collectibleType,
      categoryId: selectedCategoryId || undefined,
      searchText: debouncedSearchText || undefined,
      sportId: sportId ?? undefined,
    },
    limit: 10,
  });

  const handleApprochingEnd = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
      trackEvent({
        eventName: 'FAVORITES_SLIDER_NEXT_PAGE_REQUESTED',
        collectibleType,
      });
    }
  };
  const { moveToCategory, removeFavoriteCollectibleWithConfirmation } = useFavoriteCollectibleMutations();
  useEffect(() => {
    if (debouncedSearchText) {
      trackEvent({
        eventName: 'FAVORITES_SEARCH_APPLIED',
        searchText: debouncedSearchText,
        collectibleType,
      });
    }
  }, [debouncedSearchText, collectibleType]);

  const items = isLoading ? emptyItems : allItems.length ? allItems : [null];

  return (
    <div className={className}>
      <WidgetHeader className={classes.header}>
        {collectibleNames[collectibleType].shortSingular} Favorites
      </WidgetHeader>
      <WidgetFiltersRow>
        <FavoriteCategoryFilter
          collectibleType={collectibleType}
          selectedCategoryId={selectedCategoryId}
          onChange={(categoryId) => {
            setSelectedCategoryId(categoryId);
            trackEvent({
              eventName: 'FAVORITES_CATEGORY_SELECTED',
              collectibleType,
            });
          }}
          noSelectOnAdd
          id={`favs-${collectibleType}-select`}
        />
        <SportsSingleSelectFilter
          onChange={setSportId}
          label={'Sport'}
          labelPosition={'top'}
          value={sportId}
          noValueLabel="All"
        />
        <TextSearchField value={searchText} onChange={setSearchText} placeholder="Search" className={classes.search} />
      </WidgetFiltersRow>
      <SimpleSlider
        className={classes.slider}
        endApproachThreshold={numberOfItemsLeftToStartFetchingNextPage}
        onApproachingEnd={handleApprochingEnd}
        listClassName={classes.list}
        isEmpty={!isLoading && !allItems.length}
        emptyMessage="There are no results based upon your criteria. Please try again"
      >
        {items.map((item, index) => (
          <FavoriteTile
            className={classes.item}
            key={index}
            favoriteCollectible={item}
            collectibleType={collectibleType}
            favoriteCategories={favoriteCategories}
            onMoveToAnotherCatgory={(categoryId) => {
              if (categoryId) {
                moveToCategory.mutate({
                  categoryId,
                  favoriteId: item?.id!,
                  collectibleType,
                });
              } else {
                editFavorite({
                  collectibleId: item?.entityId!,
                  collectibleType,
                  favoriteId: item?.id,
                });
              }
            }}
            onRemoveFromFavorites={() =>
              removeFavoriteCollectibleWithConfirmation({
                id: item!.id,
                collectibleType,
              })
            }
          />
        ))}
      </SimpleSlider>
    </div>
  );
});
