import CategoryFilter, {
  CategoryFilterProps,
} from '../../sci-ui-components/collectibles/CategoryFilter/CategoryFilterOld';
import { FavoriteCategory } from '../../sci-ui-components/types/favoriteCategory';
import useFavoritePlayersCategories from './useFavoritePlayersCategories';
import { addOrEditFavoritePlayersCategory } from './AddFavoritePlayersCategoryDialog/index';
import useFavoritePlayersCategoriesMutations from './useFavoritePlayersCategoriesMutations';

export default function FavoritePlayersCategoryFilter({
  onChange,
  noSelectOnAdd = false,
  ...other
}: Omit<
  CategoryFilterProps<FavoriteCategory, number>,
  'getId' | 'getLabel' | 'categories' | 'onAddNew' | 'onChange' | 'onEdit' | 'onDelete'
> & {
  onChange: (newCategoryId: number | null) => void;
  noSelectOnAdd?: boolean;
}) {
  const { data: categories } = useFavoritePlayersCategories();
  const { removeWithConfirmation } = useFavoritePlayersCategoriesMutations();

  return (
    <CategoryFilter
      {...other}
      getId={({ id }) => id}
      getLabel={({ name, totalItems }) => `${name} (${totalItems})`}
      categories={categories}
      onChange={(v) => onChange(v!)}
      notEmpty
      onAddNew={async () => {
        const maybeNewCategory = await addOrEditFavoritePlayersCategory({});
        if (maybeNewCategory && !noSelectOnAdd) {
          onChange(maybeNewCategory.id);
        }
      }}
      onEdit={async (category) => {
        const maybeCategory = await addOrEditFavoritePlayersCategory({
          categoryId: category.id,
          name: category.name,
        });
        if (maybeCategory) {
          onChange(maybeCategory.id);
        }
      }}
      onDelete={async (category) => {
        const deleted = await removeWithConfirmation({
          categoryId: category.id,
        });
        if (deleted) {
          onChange(null);
        }
      }}
    />
  );
}
