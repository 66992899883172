import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/pro-solid-svg-icons';
import { Select } from 'antd';
import clsx from 'clsx';

import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import classes from './SimpleSelect.module.scss';
import { useSelectPopupContainer } from './useSelectPopupContainer';
import TextField from '../TextField/TextField';
import { useMemo } from 'react';

export type Option<TValue extends string | number = string, TData = void> = {
  value: TValue;
  label: string;
  disabled?: boolean;
} & (TData extends void ? {} : { data: TData });

export interface SimpleSelectProps<TValue extends string | number = string, TData = void> {
  className?: string;
  value: TValue | null;
  options: Readonly<Option<TValue, TData>[]>;
  onChange: (newValue: TValue | null, data: TData) => void;
  onSelect?: () => void;
  noValueLabel?: string;
  id?: string;
  notEmpty?: boolean;
  dropdownMatchSelectWidth?: boolean;
  showSearch?: boolean;
  filterOption?: (input: string, option: any) => boolean;
  isLoading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  renderOption?: (option: Option<TValue, TData>) => React.ReactNode;
}

const nullValue = 'null' as const;

export default function SimpleSelect<TValue extends string | number = string, TData = void>({
  className,
  value,
  options,
  onChange,
  onSelect,
  noValueLabel = 'Not Selected',
  id,
  notEmpty,
  dropdownMatchSelectWidth = false,
  filterOption,
  isLoading,
  disabled,
  readOnly,
  renderOption,
}: SimpleSelectProps<TValue, TData>) {
  const { getPopupContainer } = useSelectPopupContainer();
  const selectedValue = useMemo(
    () => options.find((option) => option.value === value)?.label ?? noValueLabel,
    [options, value, noValueLabel]
  );
  return (
    <div className={clsx(classes.selectWrapper, className)}>
      {readOnly ? (
        <TextField value={selectedValue} id={id ?? ''} readOnly />
      ) : (
        <Select
          showSearch
          filterOption={filterOption}
          optionFilterProp="children"
          suffixIcon={
            isLoading ? (
              <FontAwesomeIcon title="loading" icon={faSpinner} className="animate-spin" />
            ) : (
              <FontAwesomeIcon icon={faSortDown} />
            )
          }
          onChange={(newValue, option) => {
            onChange(newValue === nullValue ? null : newValue, option.data);
          }}
          onSelect={onSelect}
          value={value === null ? nullValue : value}
          className={classes.select}
          id={id}
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          getPopupContainer={getPopupContainer}
          disabled={disabled}
        >
          {!notEmpty && <Select.Option value={nullValue}>{noValueLabel}</Select.Option>}
          {options.map((option) => {
            const { label, value, disabled } = option;
            return (
              <Select.Option key={value} value={value} label={label} disabled={disabled}>
                {renderOption ? renderOption(option) : label}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </div>
  );
}
