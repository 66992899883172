import CategoryFilter, {
  CategoryFilterProps,
} from '../../sci-ui-components/collectibles/CategoryFilter/CategoryFilterOld';
import { FavoriteCategory } from '../../sci-ui-components/types/favoriteCategory';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import useFavoriteCollectibleCategoriesByType from './useFavoriteCollectibleCategoriesByType';
import { addOrEditFavoriteCategory } from './AddOrEditFavoriteCategoryDialog/index';
import useFavoriteCollectibleCategoriesMutations from './useFavoriteCollectibleCategoriesMutations';

export default function FavoriteCategoryFilter({
  onChange,
  noSelectOnAdd = false,
  collectibleType,
  ...other
}: Omit<
  CategoryFilterProps<FavoriteCategory, number>,
  'getId' | 'getLabel' | 'categories' | 'onAddNew' | 'onEdit' | 'onDelete'
> & {
  noSelectOnAdd?: boolean;
  collectibleType: CollectibleType;
}) {
  const { data: categories } = useFavoriteCollectibleCategoriesByType({
    collectibleType,
  });
  const { removeWithConfirmation } = useFavoriteCollectibleCategoriesMutations();

  return (
    <CategoryFilter
      {...other}
      getId={({ id }) => id}
      getLabel={({ name, totalItems }) => `${name} (${totalItems})`}
      categories={categories}
      onChange={onChange}
      onAddNew={async () => {
        const maybeNewCategory = await addOrEditFavoriteCategory({
          collectibleType: collectibleType!,
        });
        if (maybeNewCategory && !noSelectOnAdd) {
          onChange(maybeNewCategory.id);
        }
      }}
      onEdit={async (category) => {
        const maybeCategory = await addOrEditFavoriteCategory({
          categoryId: category.id,
          name: category.name,
          collectibleType,
        });
        if (maybeCategory) {
          onChange(maybeCategory.id);
        }
      }}
      onDelete={async (category) => {
        const deleted = await removeWithConfirmation({
          categoryId: category.id,
          collectibleType,
        });
        if (deleted) {
          onChange(null);
        }
      }}
    />
  );
}
