import {
  FavoriteCollectible,
  Collectible,
  CollectibleSealedWax,
  CollectibleSportsCard,
} from '@sportscardinvestor/schemas';
import {
  makeCollectibleDescriptions,
  isSportsCardNonCustomCollectible,
  isSealedNonCustomCollectible,
} from '@sportscardinvestor/collectible-helpers';
import { getCollectibleImageUrl } from '@sportscardinvestor/collectible-helpers';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import FavoritesCardTile, {
  FavoritesCardTileBaseProps,
} from '../../../sci-ui-components/collectibles/FavoritesCardTile/FavoritesCardTile';
import { FavoriteCategory } from '../../../sci-ui-components/types/favoriteCategory';
import { useCommonCollectibleActions, CommonCollectibleActions } from '../../collectibles/useCommonCollectiblesActions';

export interface FavoriteTileProps {
  className?: string;
  collectibleType: CollectibleType;
  favoriteCollectible: FavoriteCollectible | null;
  favoriteCategories?: FavoriteCategory[];
  onMoveToAnotherCatgory: FavoritesCardTileBaseProps['onMoveToAnotherCatgory'];
  onRemoveFromFavorites: FavoritesCardTileBaseProps['onRemoveFromFavorites'];
}

export default function FavoriteTile({
  collectibleType,
  favoriteCollectible,
  favoriteCategories = [],
  ...other
}: FavoriteTileProps) {
  const actions = useCommonCollectibleActions({
    collectibleId: favoriteCollectible?.collectible.id ?? null,
    collectibleType,
    query: favoriteCollectible?.collectible?.query,
  });

  if (!favoriteCollectible?.collectible || !favoriteCollectible) {
    return <FavoritesCardTile {...other} isLoading={true} collectibleType={collectibleType} />;
  }
  if (isSportsCardNonCustomCollectible(favoriteCollectible.collectible)) {
    return (
      <NonCustomSportsCardCollectibleFavoriteCardTile
        {...other}
        collectible={favoriteCollectible.collectible}
        favoriteCategories={favoriteCategories}
        favoriteCollectible={favoriteCollectible}
        actions={actions}
      />
    );
  }
  if (isSealedNonCustomCollectible(favoriteCollectible.collectible)) {
    return (
      <NonCustomSealedWaxCollectibleFavoriteCardTile
        {...other}
        collectible={favoriteCollectible.collectible}
        favoriteCategories={favoriteCategories}
        favoriteCollectible={favoriteCollectible}
        actions={actions}
      />
    );
  }
  return null; // NOTE: not expected to happen
}

type SpecificProps<TCollectible extends Collectible> = Omit<
  FavoriteTileProps,
  'collectible' | 'favoriteCollectible' | 'collectibleType'
> & {
  collectible: TCollectible;
  favoriteCollectible: FavoriteCollectible;
  actions: CommonCollectibleActions;
};

export function NonCustomSportsCardCollectibleFavoriteCardTile({
  collectible,
  favoriteCollectible,
  favoriteCategories,
  actions,
  ...other
}: SpecificProps<CollectibleSportsCard>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescriptions(collectible, {
    includePrintRun: true,
  });

  return (
    <FavoritesCardTile
      {...other}
      isLoading={false}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      grade={collectible.grade?.name}
      imageUrl={getCollectibleImageUrl(collectible)}
      isRookie={collectible.isRookie}
      lastSale={favoriteCollectible?.collectible?.stats?.lastSaleDate}
      last90DaysChangePercentage={favoriteCollectible?.collectible?.stats?.last90?.priceChangePercentage}
      last30DaysChangePercentage={favoriteCollectible?.collectible?.stats?.last30?.priceChangePercentage}
      last7DaysChangePercentage={favoriteCollectible?.collectible?.stats?.last7?.priceChangePercentage}
      lastAverageSalePrice={favoriteCollectible?.collectible?.stats?.all?.endAvgPrice}
      populationCount={collectible.stats?.currentPopulationCount}
      onPopulationCountClick={actions.chartPopulationCount}
      favoriteCategories={favoriteCategories}
      favoriteCategoryId={favoriteCollectible.categoryId}
      quickActions={{
        forSaleUrl: actions.forSaleUrl,
        onForSale: actions.trackForSaleClick,
        hasAlert: actions.hasAlert,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToCollection: actions.addToCollection,
        onChart: actions.chartCollectible,
      }}
      onClick={actions.showCollectibleFlyout}
    />
  );
}

export function NonCustomSealedWaxCollectibleFavoriteCardTile({
  collectible,
  favoriteCategories,
  favoriteCollectible,
  actions,
  ...other
}: SpecificProps<CollectibleSealedWax>) {
  const { descriptionLine1, descriptionLine2, title } = makeCollectibleDescriptions(collectible);

  return (
    <FavoritesCardTile
      {...other}
      isLoading={false}
      title={title}
      collectibleType={collectible.collectibleType}
      descriptionLine1={descriptionLine1}
      descriptionLine2={descriptionLine2}
      imageUrl={getCollectibleImageUrl(collectible)}
      lastSale={favoriteCollectible?.collectible?.stats?.lastSaleDate}
      last90DaysChangePercentage={favoriteCollectible?.collectible?.stats?.last90?.priceChangePercentage}
      last30DaysChangePercentage={favoriteCollectible?.collectible?.stats?.last30?.priceChangePercentage}
      last7DaysChangePercentage={favoriteCollectible?.collectible?.stats?.last7?.priceChangePercentage}
      lastAverageSalePrice={favoriteCollectible?.collectible?.stats?.all?.endAvgPrice}
      favoriteCategories={favoriteCategories}
      favoriteCategoryId={favoriteCollectible.categoryId}
      quickActions={{
        forSaleUrl: actions.forSaleUrl,
        onForSale: actions.trackForSaleClick,
        hasAlert: actions.hasAlert,
        onAddPriceAlert: actions.addPriceAlert,
        onAddToCollection: actions.addToCollection,
        onChart: actions.chartCollectible,
      }}
      onClick={actions.showCollectibleFlyout}
    />
  );
}
