import CategoryFilter, {
  CategoryFilterProps,
} from '../../../sci-ui-components/collectibles/CategoryFilter/CategoryFilterOld';
import { SavedSearchCategory } from '../../../sci-ui-components/types/search';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import useSavedSearchCategories from './useSavedSearchCategories';
import { editOrAddSavedSearchCategory } from './EditSavedSearchCategoryDialog/index';
import useSavedSearchCategoryMutations from './useSavedSearchCategoryMutations';
import { defaultCategoryName } from './constants';

export default function SearchCategoryFilter({
  onChange,
  noValueLabel = defaultCategoryName,
  selectedCategoryId,
  autoSelectAdded = false,
  collectibleType,
  ...other
}: Omit<
  CategoryFilterProps<SavedSearchCategory, string>,
  'getId' | 'getLabel' | 'categories' | 'onAddNew' | 'onDelete' | 'onEdit'
> & {
  autoSelectAdded?: boolean;
  collectibleType: CollectibleType;
}) {
  const { data: categories } = useSavedSearchCategories({ collectibleType });
  const { removeWithConfirmation } = useSavedSearchCategoryMutations({ collectibleType });

  return (
    <CategoryFilter
      {...other}
      selectedCategoryId={selectedCategoryId}
      noValueLabel={noValueLabel}
      getId={({ id }) => id}
      getLabel={({ name }) => name}
      categories={categories}
      onChange={onChange}
      onAddNew={async () => {
        const maybeNewCategory = await editOrAddSavedSearchCategory({ collectibleType });
        if (maybeNewCategory && autoSelectAdded) {
          onChange(maybeNewCategory.id);
        }
      }}
      onEdit={async (category) => {
        const maybeCategory = await editOrAddSavedSearchCategory({
          categoryId: category.id,
          initialName: category.name,
          collectibleType,
        });
        if (maybeCategory) {
          onChange(maybeCategory.id);
        }
      }}
      onDelete={async (category) => {
        const deleted = await removeWithConfirmation({
          categoryId: category.id,
        });
        if (deleted) {
          onChange(null);
        }
      }}
    />
  );
}
